 @font-face {
  font-family: "Burger Free";
  src: url("./../public/burger_free/Burger\ Free.otf");
  src:url("./../public/burger_free/Burger\ Free.ttf")
 }
 @font-face {
  font-family: "Baloo";
  src: url("./../public/baloo/Baloo-Regular.ttf");
 }@font-face {
  font-family: "Baloo Bhai";
  src: url("./../public/baloo/Baloo-Regular.ttf");
 }
 body{
  font-family: "Burger Free";
 }
 .font-baloo{
    font-family: "Baloo";
 } .font-baloo-bhai{
    font-family: "Baloo Bhai";

 }