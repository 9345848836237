@tailwind base;
@tailwind components;
@tailwind utilities;
body{margin: 0%;
padding: 0%;
box-sizing: border-box;
background-image: url("./components/assets/png/body-bg.png");background-size: cover;
background-repeat: no-repeat;
background-position: center;
overflow-x: hidden;}
.logo{text-shadow: 1.561px 1.04px 0px #000;
-webkit-text-stroke-width: 1px;
-webkit-text-stroke-color: #000;}
.heading{text-shadow: 5.696px 4.068px 0px #000;
-webkit-text-stroke-width: 4px;
-webkit-text-stroke-color: #000;}
.stoke-3{
  -webkit-text-stroke-width: 2px;
-webkit-text-stroke-color: #000;

}
.subheading{text-shadow: 3.7px 4.068px 0px #000;
-webkit-text-stroke-width: 2px;
-webkit-text-stroke-color: #000;
}
.join-bg{background-image: url("./components/assets/png/join.png");
  background-position: center;
  background-repeat: no-repeat;
 
}

.about-bg{background-image: url("./components/assets/png/about.png");
background-repeat: no-repeat;
background-size: cover;
background-position: center; }

.para-outline{text-shadow: 0px 2px 0px #000;
-webkit-text-stroke-width: 1px;
-webkit-text-stroke-color: #000;}


.b-1{background-image: url("./components/assets/png/b1.png");}
.b-2{background-image: url("./components/assets/png/b2.png");}
.b-3{background-image: url("./components/assets/png/b3.png");}

.card-bg{
background-repeat: no-repeat;
background-size: 100% 100%;
background-position: center;}
 .token-side{
  background-image: url("./components/assets/png/token-side.png");
  background-repeat: no-repeat;
background-size: cover;
background-position: center;}

@media (max-width:1100px) {
  .about-bg{ 
background-size: 110% 100%;
  }
}

@media (max-width:992px) {
    .stoke-3{
  -webkit-text-stroke-width: 1px;
-webkit-text-stroke-color: #000;
}.logo{text-shadow: 1px 1px 0px #000;
-webkit-text-stroke-width: 0.8px;
-webkit-text-stroke-color: #000;}
.heading{text-shadow: 4px 3px 0px #000;
-webkit-text-stroke-width: 3px;
-webkit-text-stroke-color: #000;}
.subheading{text-shadow: 3px 3.5px 0px #000;
-webkit-text-stroke-width: 1.5px;
-webkit-text-stroke-color: #000;
}
.para-outline{text-shadow: 0px 1px 0px #000;
-webkit-text-stroke-width: 0.8px;
-webkit-text-stroke-color: #000;}
    .join-bg{
    background-size: 100% 100%; 
}


}

@media  (max-width:768px) {
  .about-bg{ 
/* background-image: url("./components/assets/png/about-2.png"); */
background-size: cover;
   }
}

@media (max-width:640px) {
.logo{text-shadow: 0.7px 0.7px 0px #000;
-webkit-text-stroke-width: 0.5px;
-webkit-text-stroke-color: #000;}
.heading{text-shadow: 2px 2px 0px #000;
-webkit-text-stroke-width: 2px;
-webkit-text-stroke-color: #000;}
.subheading{text-shadow: 2px 2px 0px #000;
-webkit-text-stroke-width: 1px;
-webkit-text-stroke-color: #000;
}.para-outline{text-shadow: 0px 1px 0px #000;
-webkit-text-stroke-width: 0.4px;
-webkit-text-stroke-color: #000;}
.card-bg{
 
background-size: 110% 100%;
 }
}